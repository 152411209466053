import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, splitText, splitWithNewLineHTML } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import linkedin from '../../assets/img/linkedin.png';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
      fontWeight: 'bold',
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function TranscriptModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-12">
                        <table class="table table-bordered modal-table modal-table-deals modal-table-ceo first-letter-capitalize">
                            {item['transcript']['Company'] && <tr>
                              <th class='font-weight-bold'>Company</th>
                              <th class="text-capitalize">{item['transcript']['Company']}</th>
                            </tr>}
                            {item['transcript']['Date'] &&<tr>
                              <th class='font-weight-bold'>Date</th>
                              <th class="text-capitalize">
                                {item['transcript']['Date']}
                              </th>
                            </tr>}
                            {item['transcript']['Speaker'] &&<tr>
                              <th class='font-weight-bold'>Speaker</th>
                              <th class="text-capitalize">{item['transcript']['Speaker']}</th>
                            </tr>}
                            {item['transcript']['Title'] &&<tr>
                              <th class='font-weight-bold'>Title</th>
                              <th>{item['transcript']['Title']}</th>
                            </tr>}
                            {item['transcript']['Segments_of_Exact_Phrase'] &&<tr>
                              <th class='font-weight-bold'>Exact Phrase</th>
                              <th>
                              {item['transcript']['Segments_of_Exact_Phrase']}
                              </th>
                            </tr>}
                            {item['transcript']['Category'] &&<tr>
                              <th class='font-weight-bold'>Category</th>
                              <th>{item['transcript']['Category']}</th>
                            </tr>}
                            {item['transcript']['Condition'] &&<tr>
                              <th class='font-weight-bold'>Condition</th>
                              <th class="text-capitalize">{item['transcript']['Condition']}</th>
                            </tr>}
                            {item['transcript']['Product_Name'] &&<tr>
                              <th class='font-weight-bold'>Product Name</th>
                              <th>
                                {item['transcript']['Product_Name']}
                              </th>
                            </tr>}
                            {item['transcript']['Product_Generic_Name'] &&<tr>
                              <th class='font-weight-bold'>Generic Name</th>
                              <th class="text-capitalize">
                                {item['transcript']['Product_Generic_Name']}
                              </th>
                            </tr>}
                            {item['transcript']['Trial_Name'] &&<tr>
                              <th class='font-weight-bold'>Trial Name</th>
                              <th class="text-capitalize">
                                {item['transcript']['Trial_Name']}
                              </th>
                            </tr>}
                            {item['transcript']['Topic'] &&<tr>
                              <th class='font-weight-bold'>Topic</th>
                              <th>{item['transcript']['Topic']}</th>
                            </tr>}
                            {item['transcript']['Subtopic'] &&<tr>
                              <th class='font-weight-bold'>Subtopic</th>
                              <th>{item['transcript']['Subtopic']}</th>
                            </tr>}
                            {item['transcript']['Data'] &&<tr>
                              <th class='font-weight-bold'>Data</th>
                              <th>{item['transcript']['Data']}</th>
                            </tr>}
                            {item['transcript']['Actual_Expected_or_Hoping'] &&<tr>
                              <th class='font-weight-bold'>Actual Expected or Hoping</th>
                              <th>{item['transcript']['Actual_Expected_or_Hoping']}</th>
                            </tr>}
                            {item['transcript']['Sentiment'] &&<tr>
                              <th class='font-weight-bold'>Sentiment</th>
                              <th>{item['transcript']['Sentiment']} 
                                <Brightness1Icon
                                    style={{
                                      fontSize:'30px',  
                                      color: 
                                        item['transcript']['Sentiment']=='negative'
                                        ?'#FFAEAE':
                                        item['transcript']['Sentiment']=='positive'
                                        ?'#9fe896'
                                        :'#6b6767'}}
                                />
                              </th>
                            </tr>}
                            {item['transcript']['Any_other_Company_Mentioned'] &&<tr>
                              <th class='font-weight-bold'>Company Mentioned</th>
                              <th>{item['transcript']['Any_other_Company_Mentioned']}</th>
                            </tr>}
                            {item['transcript']['Country_Region_if_specifically_mentioned'] &&<tr>
                              <th class='font-weight-bold'>Country Region</th>
                              <th>{item['transcript']['Country_Region_if_specifically_mentioned']}</th>
                            </tr>}
                            {item['transcript']['AXL_Statement'] &&<tr>
                              <th class='font-weight-bold'>Statement</th>
                              <th>{item['transcript']['AXL_Statement']}</th>
                            </tr>}
                        </table>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default TranscriptModal;