import React, { useState, useEffect } from 'react';
import TrialsModal from '../../ActiveTrialsModal';
import PhysiciansModal from '../../Physicians/PhysiciansModal';
import TreatmentModal from '../../ApprovedTreatmentsModal';
import PlayersModal from '../../PlayersModal';
import CompanyDealsModal from '../../CompanyDealsModal';
import GenericModal from '../../GenericModal';
import DescriptionIcon from '@material-ui/icons/Description';
import { numberWithCommas, splitText, removeDayFromDate, amountToAbbreviateNumber } from '../../Helper';
import AxlChips from '../MUI/AxlChips';

const LeafRow = ({options, item ,apiCallRequest}) => {
  const [selectedItem, setSelectedItem] = useState([]); // State to keep track of selected item
  const [showModal, setShowModal] = useState(false); // State to toggle modal visibility
  const columnSize = options.trialType=='phases'?35:50;
  const [axlCompanyId, setAxlCompanyId] = useState(item['axl_company_id']?item['axl_company_id']:'');
  const handleCallRequest = () => {
    if(item['name'] != options.levelLeaf){
      apiCallRequest(item['name'], 0,'levelLeaf', 'leaf_no', item['parent'], item['sub_parent'], item['sub_sub_parent'])
    }
    setShowModal(true);
  }
  const handleClose = () => {
    setShowModal(false)
  }
  // console.log(options.leafModalData)
  return (
    <>
      <div 
        className={`expandable-row ${options.expandableRowLevelUI=='level1' ? 'appr-subheading' : 'leaf-row'}`}
        onClick={handleCallRequest}
      >
        <div className='expandable-cell text-capitalize'>
          { 
          options.playerType=='category' && axlCompanyId?
              <a style={{color:'#000000'}} target="_blank" href={`Company/${axlCompanyId}/${item['name']}/overview`}>
                {item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':splitText(item['name'].toLowerCase(),';')}
              </a>
            
            :
              item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':splitText(item['name'].toLowerCase(),';')
          }
        </div>
        {options.trialType == 'players' || options.trialType == 'deals' ? options.column_keys.map((column_value) => {
            return (
              <div className='expandable-cell'>
                {
                  options.trialType == 'deals'?
                  column_value == 'axl_deal_date' ? removeDayFromDate(item[column_value]) : 
                  column_value == 'target_portfolio' ? <div className="chips-container p-0 m-0">
                      <AxlChips options={{chips: item[column_value], size: 'small'}} />
                  </div>:
                  column_value == 'total' ? amountToAbbreviateNumber(item[column_value]) :
                  item[column_value]:numberWithCommas(item[column_value])}</div>
            )})
          :
            <div
              className='expandable-cell'
              style={{textAlign: 'right'}}
            >
              <div class="col-12 p-0">
                    <div class="row">
                      {options.trialType == 'phases' ? options.column_keys.map((column_value) => {
                          return (
                            <>
                              <div class="col">
                                {numberWithCommas(item[column_value])}
                              </div>
                            </>
                          );
                          }) : <div class="col">{options.icon?options.icon:''}
                          {numberWithCommas(item['total'])}</div>
                      }
                    </div>
                </div>
            </div>
          }
      </div>
      {showModal && (
        options.leafModalData && options.leafModalData.name == item['name']? (
          options.trialType == 'drug' ? (<GenericModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
          options.trialType == 'deals' ? (<CompanyDealsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : 
          options.trialType == 'players' ? (<PlayersModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : 
          options.trialType == 'physicians' ? (<PhysiciansModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : 
          options.trialType == 'trials' || options.trialType == 'phases' || options.trialType == 'trialsDrug' ? 
          ( <TrialsModal item={options.leafModalData} onClose={handleClose} /> ) :
          (<TreatmentModal item={options.leafModalData} onClose={() => setShowModal(false)} category={item['name']} />)
        ) : null
      )}
    </>
   
    
  );
};

export default LeafRow;